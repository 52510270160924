import { readValidatedBody, type H3Event } from "h3";
import { z } from "zod";
import type {
  BackendAssignmentEntry,
  BackendCaseEmail,
  BackendCaseNoteEntry,
  BackendCaseStatusEntry,
  BackendDueDateEntry,
} from "~/src/models/Case/History.model";
import type {
  BackendScreenResultViewModel,
  ScreenResultViewModel,
} from "~/src/models/Case/Screen.model";
import { CaseStatus, type MetadataValue } from "~/src/models/Case/Shared.model";
import type { UserInformation } from "~/src/models/User.model";
import type { ListRequestModel, SortModel } from "~/src/models/utils/Api.model";
import type { SerialisedDate } from "~/src/models/utils/Serialise.model";
import {
  mapFilters,
  mapFrontendFlagFilters,
} from "~/src/models/utils/Filter.model";
import { frontendQueryFilterSchema } from "../utils/Filter.model";

export type BackendCaseViewModel = {
  caseId: string;
  createdAt: SerialisedDate;
  lastScreeningDate: SerialisedDate;
  screenResults: BackendScreenResultViewModel[];
  caseDetails: Detail[];
  caseNotes: BackendCaseNoteEntry[];
  caseStatus: BackendCaseStatusEntry;
  statusHistory: BackendCaseStatusEntry[];
  assignments: BackendAssignmentEntry[];
  emails: BackendCaseEmail[];
  flags: CaseFlags;
  dueDates: BackendDueDateEntry[];
};

export type CaseViewModel = {
  caseId: string;
  // In case the ID and the reference are not the same in future
  caseReference: string;
  createdAt: SerialisedDate;
  assignedTo: UserInformation | null;
  flags: CaseFlags;

  latestScreening: ScreenResultViewModel;
  previousScreenings: ScreenResultViewModel[];
  caseStatus: CaseStatusSummaryViewModel | null;
  caseDetails: Detail[];
  dueDate: SerialisedDate | null;
  isInspectionCase: false;
};

export type DummyCaseViewModel = Omit<
  CaseViewModel,
  "latestScreening" | "previousScreenings" | "isInspectionCase"
> & { isInspectionCase: true };

export function isFullCase(
  caseView: CaseViewModel | DummyCaseViewModel
): caseView is CaseViewModel {
  return !caseView.isInspectionCase;
}

export type CaseListFullCaseViewModel = {
  caseId: string;
  // In case the ID and the reference are not the same in future
  caseReference: string;
  createdAt: SerialisedDate;
  assignedTo: UserInformation | null;
  flags: CaseFlags;

  latestScreening: ScreenResultViewModel | null;
  previousScreenings: ScreenResultViewModel[] | null;
  caseStatus: CaseStatusSummaryViewModel | null;
  dueDate: SerialisedDate | null;
  caseDetails: Detail[];
  isInspectionCase: false;
  lastScreeningSummary: ScreeningSummaryViewModel | null;
};
export type CaseListViewModel = CaseListFullCaseViewModel | DummyCaseViewModel;

export type BackendCaseStatusEntryViewModel = {
  status: CaseStatus;
  metadata?: MetadataValue[];
  createdBy: string;
  createdAt: SerialisedDate;
};

export type CaseStatusReasonViewModel = {
  value: string;
  displayValue: string;
};

export type Detail = {
  source: string;
  key: string;
  value: string;
};

export type CaseFlags = {
  suspectedUndeclaredDg: boolean;
  suspectedMisdeclaredDg: boolean;
  unusualBehaviour: boolean;
  documentAuthenticity: boolean;
  bookingIrregularity: boolean;
  isInspectionCase: boolean;
};
export type CaseFlag = keyof CaseFlags;

export type CaseStatusSummaryViewModel = {
  status: CaseStatus;
  reason?: CaseStatusReasonViewModel;
  metadata?: MetadataValue[];
  createdBy: string;
  createdAt: SerialisedDate;
};

export type ScreeningSummaryViewModel = {
  id: string | null;
  amountOfHits: number;
  amountOfHitsDelta: number | null;
  date: SerialisedDate | null;
  amountOfScreeningsTotal: number;
};

export const assignmentSchema = z.enum(["unassigned", "mine", "all"]);
export type Assignment = "unassigned" | "mine" | "all";

export const caseListOption = z.enum([
  "WithScreenResults",
  "WithScreenResultsHits",
  "WithScreenResultsMatches",
  "WithCaseDetails",
  "WithCaseStatusHistory",
  "WithAssignment",
  "WithDueDates",
]);
export type CaseListOption = z.infer<typeof caseListOption>;

export type CasesListQueryRequest = ListRequestModel & {
  assignment?: Assignment;
  caseListOptions: CaseListOption[];
};

export const STATUS_ICONS: Record<CaseStatus, string> = {
  [CaseStatus.Open]: "mdi:check-bold",
  [CaseStatus.Closed]: "mdi:progress-close",
  [CaseStatus.InProgress]: "mdi:progress-clock",
  [CaseStatus.Processed]: "mdi:progress-check",
};

export const EXTRA_CASE_TABLE_COLUMNS = [
  "New",
  "Rescreening",
  "Is DG",
  "Highest Risk",
  "Date Created",
  "Last Screening Date",
] as const;

export type ExtraCaseTableColumn = (typeof EXTRA_CASE_TABLE_COLUMNS)[number];

export const CASES_STATUS_NAMES = {
  [CaseStatus.Open]: "general.open",
  [CaseStatus.InProgress]: "general.in_progress",
  [CaseStatus.Closed]: "general.closed",
  [CaseStatus.Processed]: "general.processed",
} as const;

export type Links2 = {
  self: Self2;
  setStatus: SetStatus;
  assign: Assign;
};

export type Self2 = {
  href: string;
  method: string;
};

export type SetStatus = {
  href: string;
  method: string;
};

export type Assign = {
  href: string;
  method: string;
};

export const InspectionState = z.enum([
  "Not Requested",
  "Requested",
  // 'Not Required',
  "In Progress",
  "Completed",
  "Failed",
]);

const defaultCaseListOptions = [
  caseListOption.Values.WithScreenResultsMatches,
  caseListOption.Values.WithCaseStatusHistory,
  caseListOption.Values.WithAssignment,
  caseListOption.Values.WithDueDates,
  caseListOption.Values.WithCaseDetails,
];

export const getCasesBodySchema = z.object({
  page: z.number(),
  pageSize: z.number().default(20),
  sortBy: z.string().optional().nullable(),
  sortOrder: z
    .union([z.literal("asc"), z.literal("desc")])
    .optional()
    .nullable(),
  filters: z.array(frontendQueryFilterSchema).optional(),
  caseListOptions: z
    .array(caseListOption)
    .optional()
    .default(defaultCaseListOptions),
  assignment: assignmentSchema.optional().catch("all"),
});
export type GetCasesBody = z.infer<typeof getCasesBodySchema>;
export type GetCasesBodyInput = z.input<typeof getCasesBodySchema>;

export function getSort({
  sortBy,
  sortOrder,
}: GetCasesBody): SortModel | undefined {
  if (!sortBy) return undefined;
  return { [sortBy]: sortOrder || "asc" };
}

export async function getCasesListBody(event: H3Event, noInclude?: boolean) {
  const body = await readValidatedBody(event, getCasesBodySchema.parse);

  const filters = body.filters
    ? mapFrontendFlagFilters(body.filters)
    : undefined;
  const filterBody = filters ? mapFilters(filters) : undefined;

  return {
    page: body.page,
    size: body.pageSize,
    sort: getSort(body),
    assignment: body.assignment,
    filter: filterBody,
    caseListOptions: noInclude ? undefined : body.caseListOptions,
  };
}
